//
//
//
//

export default {
  data() {
    return {};
  },
  created() {
    this.$router.replace({path:'/StuL',query: {clickEnent:'费用明细'}}).catch((err) => {
      console.log(err);
    });
  },
  methods: {},
};
